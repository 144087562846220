module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mag-multi-lang-site","accessToken":"MC5ZblZvN0JFQUFDOEE4Uzc0.J--_ve-_ve-_vUfvv70z77-9fO-_ve-_vUrvv73vv71PZu-_ve-_ve-_vVjvv71Ia--_vW7vv71zfD1NKFg","promptForAccessToken":true,"apiEndpoint":"https://mag-multi-lang-site.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"C:\\codebuild\\tmp\\output\\src3000046303\\src\\codestar-connections.us-west-2.amazonaws.com\\git-http\\603400662265\\us-west-2\\42625e79-deed-4f1d-8499-8b7906bc0d98\\MarkAnthonyGroup\\www.artisanwineco.ca\\src\\images\\favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"99effd6826856cf6dbb9bca2d0e1c5e5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TZD8VH3","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\r\n          return {\r\n            pageType: window.pageType,\r\n          }\r\n        }"},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
