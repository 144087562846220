/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */

exports.linkResolver = (doc) => {
  var returnLink;
  if(doc.type === "find_wine_by_details_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "single_brand_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "about_us_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "homepage"){
    returnLink = "/"
  }else if(doc.type === "find_your_wine_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "our_brands_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "contact_us_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "find_perfect_wine_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "error_page"){
    returnLink = "/error"
  }else if(doc.type === "where_to_buy"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "general_documents_page"){
    returnLink = `/${doc.uid}`
  }else if(doc.type === "wishpond_page"){
    returnLink = `/${doc.uid}`
  }else{
    //whats_my_wine_module
    //our_brands_section
    returnLink = "/"
  }

  return returnLink
}