exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-v-js": () => import("./../../../src/pages/5V.js" /* webpackChunkName: "component---src-pages-5-v-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bee-kind-js": () => import("./../../../src/pages/bee-kind.js" /* webpackChunkName: "component---src-pages-bee-kind-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-curious-incident-js": () => import("./../../../src/pages/curious-incident.js" /* webpackChunkName: "component---src-pages-curious-incident-js" */),
  "component---src-pages-diabolica-js": () => import("./../../../src/pages/diabolica.js" /* webpackChunkName: "component---src-pages-diabolica-js" */),
  "component---src-pages-fern-walk-js": () => import("./../../../src/pages/fern-walk.js" /* webpackChunkName: "component---src-pages-fern-walk-js" */),
  "component---src-pages-find-your-wine-js": () => import("./../../../src/pages/find-your-wine.js" /* webpackChunkName: "component---src-pages-find-your-wine-js" */),
  "component---src-pages-forty-nine-north-js": () => import("./../../../src/pages/forty-nine-north.js" /* webpackChunkName: "component---src-pages-forty-nine-north-js" */),
  "component---src-pages-grow-wild-js": () => import("./../../../src/pages/grow-wild.js" /* webpackChunkName: "component---src-pages-grow-wild-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offshore-js": () => import("./../../../src/pages/offshore.js" /* webpackChunkName: "component---src-pages-offshore-js" */),
  "component---src-pages-our-brands-js": () => import("./../../../src/pages/our-brands.js" /* webpackChunkName: "component---src-pages-our-brands-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-petrichor-js": () => import("./../../../src/pages/petrichor.js" /* webpackChunkName: "component---src-pages-petrichor-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-prospect-js": () => import("./../../../src/pages/prospect.js" /* webpackChunkName: "component---src-pages-prospect-js" */),
  "component---src-pages-rigamarole-js": () => import("./../../../src/pages/rigamarole.js" /* webpackChunkName: "component---src-pages-rigamarole-js" */),
  "component---src-pages-saturday-sun-js": () => import("./../../../src/pages/saturday-sun.js" /* webpackChunkName: "component---src-pages-saturday-sun-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */),
  "component---src-templates-find-perfect-wine-js": () => import("./../../../src/templates/find-perfect-wine.js" /* webpackChunkName: "component---src-templates-find-perfect-wine-js" */),
  "component---src-templates-find-wine-by-details-js": () => import("./../../../src/templates/find-wine-by-details.js" /* webpackChunkName: "component---src-templates-find-wine-by-details-js" */),
  "component---src-templates-wishpond-page-js": () => import("./../../../src/templates/wishpond-page.js" /* webpackChunkName: "component---src-templates-wishpond-page-js" */)
}

